@font-face {
  font-family: 'RipeApricot';
  src: url('/fonts/RPA55.ttf');
}

@font-face {
  font-family: 'RipeApricotBold';
  src: url('/fonts/RPA75.ttf');
}

@font-face {
  font-family: 'librefranklin';
  src: url('/fonts/librefranklin-regular.ttf');
}

@font-face {
  font-family: 'librefranklinbold';
  src: url('/fonts/librefranklin-bold.ttf');
}

html, body {
  background-color: #f4f4f4;
}

body {
  margin: 0;
  font-family: RipeApricot, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

th, th > span {
  font-family: 'librefranklinbold' !important;
}

.MuiPaper-root.makeStyles-drawerPaper-11.MuiDrawer-paperAnchorDockedLeft span {
  font-size: 13px;
}

.policy-number {
  display: none;
}

#bottom-container {
  overflow: auto;
}
